import styled from 'styled-components';

export const Container = styled.span`
  & {
    color: inherit;
    line-height: inherit;
    -webkit-letter-spacing: inherit;
    -moz-letter-spacing: inherit;
    -ms-letter-spacing: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-align: inherit;
    font-family: inherit;
  }
  & p {
    color: inherit;
    line-height: inherit;
    -webkit-letter-spacing: inherit;
    -moz-letter-spacing: inherit;
    -ms-letter-spacing: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-align: inherit;
    font-family: inherit;
  }

  & p:first-of-type {
    margin: 0;
  }
`;
